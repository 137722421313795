import * as baseKemedis from './base-kemedis.api';
import * as management from './management-api';
import * as location from './location-api';
import * as practitioner from './practitioner-api';
import * as administrative from './administrative-api';
import * as medicine from './medicine-api';
import * as inventory from './inventory-api';
import * as kfaBrand from './kfa-brand-api';
import * as providerProcedure from './provider-procedure-api';
import * as encounter from './encounter-api';
import * as patient from './patient-api';
import * as pharmacyRecipe from './pharmacy-recipe-api';
import * as pharmacySale from './pharmacy-sale-api';
import * as payment from './payment-api';
import * as bill from './bill-api';
import * as materialReport from './material-report-api';
import * as guarantor from './guarantor-api';
import * as mainDashboard from './main-dashboard-api';
import * as procedureReport from './procedure-report-api';
import * as soap from './soap-api';
import * as icd10 from './icd10-api';
import * as icd9 from './icd9-api';
import * as cloudinary from './cloudinary-api';
import * as healthCareProvider from './health-care-provider-api';
import * as medicalDevice from './medical-device-api';
import * as account from './account-api';
import * as onboardingAdministrative from './onboarding-administrative-api';
import * as practitionerSchedule from './practitioner-schedule-api';
import * as appointment from './appointment-api';
import * as bpjsVClaim from './bpjs-vclaim-api';
import * as bpjsPCare from './bpjs-pcare-api';
import * as bpjsQueue from './bpjs-queue-api';
import * as bpjsVClaimMember from './v-claim-member.api';
import * as vclaimSep from './vclaim-sep-api';
import * as vclaimRef from './vclaim-ref-api';
import * as vclaimRencanaKontrol from './vclaim-rencana-kontrol-api';
import * as vclaimRujukan from './vclaim-rujukan-api';
import * as vclaimMonitoring from './vclaim-monitoring-api';

export default {
  baseKemedis,
  management,
  location,
  practitioner,
  practitionerSchedule,
  administrative,
  medicine,
  inventory,
  kfaBrand,
  providerProcedure,
  encounter,
  patient,
  pharmacyRecipe,
  pharmacySale,
  payment,
  bill,
  materialReport,
  guarantor,
  mainDashboard,
  procedureReport,
  soap,
  icd10,
  icd9,
  cloudinary,
  healthCareProvider,
  medicalDevice,
  account,
  onboardingAdministrative,
  appointment,
  bpjsVClaim,
  bpjsPCare,
  bpjsQueue,
  bpjsVClaimMember,
  vclaimSep,
  vclaimRef,
  vclaimRencanaKontrol,
  vclaimRujukan,
  vclaimMonitoring,
};
