import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Account
  AccountActivatePage,

  // Auth
  LoginPage,
  OnboardingPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  ResetSuccessPage,
  // Dashboard: General
  GeneralAppPage,
  //
  Page500,
  Page403,
  Page404,
  MaintenancePage,

  // DASHBOARD KEMEDIS

  // master ihs
  MasterIhsLocation,
  MasterIhsLocationCreate,
  MasterIhsLocationUpdate,
  MasterIhsPractitioner,
  MasterIhsPractitionerCreate,
  MasterIhsPractitionerUpdate,
  // end master ihs

  // master pharmacy
  MasterPharmacyMedicineInput,
  MasterPharmacyMedicineInputBrandCreate,
  MasterPharmacyMedicineInputBrandUpdate,
  MasterPharmacyMedicineInputMedicineCreate,
  MasterPharmacyMedicineInputMedicineUpdate,
  MasterPharmacyMedicineInputMedicalDeviceCreate,
  MasterPharmacyMedicineInputMedicalDeviceUpdate,
  // end master pharmacy

  // master product services
  MasterProductServicesProduct,
  MasterProductServicesProductCreate,
  MasterProductServicesProductUpdate,
  // end master product services
  // master guarantor
  MasterGuarantor,
  MasterGuarantorCreate,
  MasterGuarantorUpdate,
  // end master guarantor
  // SECTION MANAGEMENT

  // management - employees
  ManagementEmployeesListPage,
  ManagementEmployeesCreatePage,
  // end management
  ManagementEmployeesUpdatePage,
  // end management - employees

  // management roles
  ManagementRolesListPage,
  // end management roles

  // management permissions
  ManagementPermissionsListPage,
  ManagementPermissionsCreatePage,
  ManagementPermissionsUpdatePage,
  // end management permissions

  // END SECTION MANAGEMENT

  // SECTION INVENTORY
  // inventory - stock medicines
  InventoryStockMedicinesListPage,
  //  end inventory - stock medicines
  // inventory - adjust stock medicines
  InventoryAdjustStockMedicinesListPage,
  InventoryAdjustStockMedicinesCreatePage,
  InventoryAdjustStockMedicinesUpdatePage,
  // end inventory - adjust stock medicines
  // END SECTION INVENTORY

  // SECTION PHARMACY SERVICE

  // pharmacy service - e-prescribing
  PharmacyServiceEPrescribing,
  PharmacyServiceEPrescribingVerify,
  PharmacyServiceEPrescribingUpdate,
  PharmacyServiceEPrescribingSubmission,
  // end pharmacy service - e-prescribing
  // END SECTION PHARMACY SERVICE

  // SECTION CASHIER
  // cashier - general
  CashierGeneral,
  CashierGeneralCreate,
  CashierGeneralPrintThermal,
  // end cashier - general

  // cashier - outpatient
  CashierOutpatient,
  CashierOutpatientCreate,
  CashierOutpatientPrintThermal,
  // end cashier - outpatient

  // END SECTION CASHIER

  // admission
  AdmissionPatientRegistrationListPage,
  AdmissionPatientRegistrationCreatePage,
  AdmissionPatientRegistrationSearchExistingPage,
  AdmissionPatientRegistrationCreateExistingPage,
  AdmissionPatientDataListPage,
  AdmissionPatientDataCreatePage,
  AdmissionPatientDataUpdatePage,
  AdmissionPatientRegistrationUpdatePage,
  CashierGeneralView,
  // end admission

  // report
  ReportsMaterialSale,
  ReportsProcedureService,
  // end report

  // service
  ServiceOutpatientListPage,
  ServiceOutpatientUpdatePage,
  ServiceOutpatientInitAssessmentPage,
  MasterIhsPractitionerSchedule,
  AdmissionBooking,
  AdmissionBookingCreate,
  AdmissionBookingConfirmation,
  AdmissionBookingAfterConfirmation,
  ServiceInpatientListPage,
  AdmissionBookingSearchExistingPage,
  AdmissionBookingCreateExistingPage,
  FeaturesInitPage,
  IntegrationsBpjsQueue,
  IntegrationsBpjsPCare,
  IntegrationsBpjsVClaim,
  IntegrationsBpjsQueueCreate,
  IntegrationsBpjsVClaimCreate,
  IntegrationsBpjsPCareCreate,
  ServiceOutpatientIcd10Page,
  BPJSSepListPage,
  BPJSSepCreatePage,
  AdmissionPatientRegistrationCreateSepPage,
  InventoryMaterialReceiptCreatePage,
  InventoryMaterialReceiptListPage,
  AdmissionPatientRegistrationCreateVisitPlanPage,
  AdmissionPatientRegistrationCreateVisitPlanNonBPJSPage,
  AdmissionPatientRegistrationCreateRujukanPage,
  BPJSMonitoring,
  AdmissionPatientRegistrationUpdateSepPage,
  AdmissionPatientRegistrationUpdateVisitPlanPage,
  AdmissionPatientRegistrationCreatePenjaminanPage,
  AdmissionPatientRegistrationUpdateRujukanPage,
  // service

  // END DASHBOARD KEMEDIS
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // account
    {
      path: 'acccount',
      children: [
        {
          element: <CompactLayout />,
          children: [{ path: 'activate', element: <AccountActivatePage /> }],
        },
      ],
    },

    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: ':step/onboarding',
          element: (
            <GuestGuard>
              <OnboardingPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'reset/finish', element: <NewPasswordPage /> },
            { path: ':email/verify', element: <VerifyCodePage /> },
            { path: 'activate', element: <AccountActivatePage /> },
            { path: 'reset-success', element: <ResetSuccessPage /> },
          ],
        },
      ],
    },

    {
      path: 'master',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to="/master/ihs/organization" replace />,
        },
      ],
    },
    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'features', element: <FeaturesInitPage /> },
        { path: 'app', element: <GeneralAppPage /> },

        // DASHBOARD KEMEDIS
        {
          path: 'master',
          children: [
            {
              path: 'ihs',
              children: [
                {
                  element: <Navigate to="/dashboard/master/ihs/location" replace />,
                  index: true,
                },
                // location
                { path: 'location', element: <MasterIhsLocation /> },
                { path: 'location/create', element: <MasterIhsLocationCreate /> },
                { path: 'location/:id/update', element: <MasterIhsLocationUpdate /> },
                // end location

                // practitioner
                { path: 'practitioner', element: <MasterIhsPractitioner /> },
                { path: 'practitioner/create', element: <MasterIhsPractitionerCreate /> },
                {
                  path: 'practitioner/:id/update',
                  element: <MasterIhsPractitionerUpdate />,
                },
                {
                  path: 'practitioner/:id/create-schedule',
                  element: <MasterIhsPractitionerSchedule />,
                },
                // end practitioner
              ],
            },

            // pharmacy
            {
              path: 'pharmacy',
              children: [
                {
                  element: <Navigate to="/dashboard/master/pharmacy/medicine-input" replace />,
                  index: true,
                },
                // medicine input
                {
                  path: 'medicine-input',
                  element: <MasterPharmacyMedicineInput />,
                },
                {
                  path: 'medicine-input/create',
                  element: <MasterPharmacyMedicineInputBrandCreate />,
                },
                {
                  path: 'medicine-input/:id/update',
                  element: <MasterPharmacyMedicineInputBrandUpdate />,
                },
                {
                  path: 'medicine-input/create-medicine',
                  element: <MasterPharmacyMedicineInputMedicineCreate />,
                },
                {
                  path: 'medicine-input/:id/update-medicine',
                  element: <MasterPharmacyMedicineInputMedicineUpdate />,
                },
                {
                  path: 'medicine-input/create-medical-device',
                  element: <MasterPharmacyMedicineInputMedicalDeviceCreate />,
                },
                {
                  path: 'medicine-input/:id/update-medical-device',
                  element: <MasterPharmacyMedicineInputMedicalDeviceUpdate />,
                },
                // end medicine input
              ],
            },
            // end pharmacy

            // product and services
            {
              path: 'product-and-service',
              children: [
                {
                  element: <Navigate to="/dashboard/master/product-and-service/product" replace />,
                  index: true,
                },
                {
                  path: 'product',
                  element: <MasterProductServicesProduct />,
                },
                {
                  path: 'product/create',
                  element: <MasterProductServicesProductCreate />,
                },
                {
                  path: 'product/:id/update',
                  element: <MasterProductServicesProductUpdate />,
                },
              ],
            },
            // end product and services

            // guarantor
            {
              path: 'guarantor',
              element: <MasterGuarantor />,
            },
            {
              path: 'guarantor/create',
              element: <MasterGuarantorCreate />,
            },
            {
              path: 'guarantor/:id/update',
              element: <MasterGuarantorUpdate />,
            },
            // end guarantor
          ],
        },

        // management
        {
          path: 'management',
          children: [
            { element: <Navigate to="/dashboard/management/employees" replace />, index: true },
            // employees
            { path: 'employees', element: <ManagementEmployeesListPage /> },
            { path: 'employees/create', element: <ManagementEmployeesCreatePage /> },
            { path: 'employees/:id/update', element: <ManagementEmployeesUpdatePage /> },
            // end employees

            // roles
            { path: 'roles', element: <ManagementRolesListPage /> },
            // end roles

            // permissions
            { path: 'permissions', element: <ManagementPermissionsListPage /> },
            { path: 'permissions/create', element: <ManagementPermissionsCreatePage /> },
            { path: 'permissions/:email/update', element: <ManagementPermissionsUpdatePage /> },
            // end permissions
          ],
        },
        // end management
        // inventory
        {
          path: 'inventory',
          children: [
            {
              element: <Navigate to="/dashboard/inventory/stock-medicines" replace />,
              index: true,
            },
            // stock medicines
            { path: 'stock-medicines', element: <InventoryStockMedicinesListPage /> },
            // end stock medicines

            // adjust stock medicines
            { path: 'adjust-stock-medicines', element: <InventoryAdjustStockMedicinesListPage /> },
            {
              path: 'adjust-stock-medicines/create',
              element: <InventoryAdjustStockMedicinesCreatePage />,
            },
            {
              path: 'adjust-stock-medicines/:id/update',
              element: <InventoryAdjustStockMedicinesUpdatePage />,
            },
            // end adjust stock medicines

            {
              path: 'material-receipt',
              element: <InventoryMaterialReceiptListPage />,
            },
            {
              path: 'material-receipt/create',
              element: <InventoryMaterialReceiptCreatePage />,
            },
          ],
        },
        // end inventory

        // pharmacy service
        {
          path: 'pharmacy-service',
          children: [
            {
              element: <Navigate to="/dashboard/pharmacy-service/e-prescribing" replace />,
              index: true,
            },
            // e-prescribing
            { path: 'e-prescribing', element: <PharmacyServiceEPrescribing /> },
            { path: 'e-prescribing/:id/verify', element: <PharmacyServiceEPrescribingVerify /> },
            { path: 'e-prescribing/:id/update', element: <PharmacyServiceEPrescribingUpdate /> },
            {
              path: 'e-prescribing/:id/submission',
              element: <PharmacyServiceEPrescribingSubmission />,
            },
            // end e-prescribing
          ],
        },
        // end pharmacy service

        // cashier
        {
          path: 'cashier',
          children: [
            {
              element: <Navigate to="/dashboard/cashier/general" replace />,
              index: true,
            },
            {
              path: 'general',
              element: <CashierGeneral />,
            },
            {
              path: 'general/create',
              element: <CashierGeneralCreate />,
            },
            {
              path: 'general/:id/repeat-order',
              element: <CashierGeneralCreate />,
            },
            {
              path: 'general/:id/view',
              element: <CashierGeneralView />,
            },
            {
              path: 'general/:id/thermal',
              element: <CashierGeneralPrintThermal />,
            },

            // outpatient
            {
              path: 'outpatient',
              element: <CashierOutpatient />,
            },
            {
              path: 'outpatient/create',
              element: <CashierOutpatientCreate />,
            },
            {
              path: 'outpatient/:id/pay',
              element: <CashierOutpatientCreate />,
            },
            {
              path: 'outpatient/:id/thermal/:type',
              element: <CashierOutpatientPrintThermal />,
            },
            // end outpatient
          ],
        },
        // end cashier

        // start bpjs
        {
          path: 'bpjs',
          children: [
            {
              element: <Navigate to="/dashboard/bpjs/vclaim/sep" replace />,
              index: true,
            },
            // sep
            {
              path: 'vclaim',
              children: [
                {
                  path: 'monitoring',
                  element: <BPJSMonitoring />,
                },
                {
                  path: 'sep',
                  element: <BPJSSepListPage />,
                },
                {
                  path: 'sep/:id/create/:tglSEP',
                  element: <BPJSSepCreatePage />,
                },
              ],
            },
            // end sep
          ],
        },
        // end bpjs

        // start admission
        {
          path: 'admission',
          children: [
            {
              element: <Navigate to="/dashboard/admission/booking" replace />,
              index: true,
            },

            // booking
            {
              path: 'booking',
              element: <AdmissionBooking />,
            },
            {
              path: 'booking/create',
              element: <AdmissionBookingCreate />,
            },
            {
              path: 'booking/:id/confirmation',
              element: <AdmissionBookingConfirmation />,
            },
            {
              path: 'booking/:id/:appointmentId/after-confirmation',
              element: <AdmissionBookingAfterConfirmation />,
            },
            {
              path: 'booking/existing/search',
              element: <AdmissionBookingSearchExistingPage />,
            },
            {
              path: 'booking/existing/:patientId/create',
              element: <AdmissionBookingCreateExistingPage />,
            },
            // end booking
            // patient registration
            { path: 'patient-registration', element: <AdmissionPatientRegistrationListPage /> },
            {
              path: 'patient-registration/create',
              element: <AdmissionPatientRegistrationCreatePage />,
            },
            {
              path: 'patient-registration/:id/update',
              element: <AdmissionPatientRegistrationUpdatePage />,
            },
            {
              path: 'patient-registration/existing/search',
              element: <AdmissionPatientRegistrationSearchExistingPage />,
            },
            {
              path: 'patient-registration/existing/:patientId/create',
              element: <AdmissionPatientRegistrationCreateExistingPage />,
            },
            {
              path: 'patient-registration/:id/create/:tglSEP',
              element: <AdmissionPatientRegistrationCreateSepPage />,
            },
            {
              path: 'patient-registration/:id/update/:tglSEP',
              element: <AdmissionPatientRegistrationUpdateSepPage />,
            },
            {
              path: 'patient-registration/:id/:tglSEP/create-penjaminan',
              element: <AdmissionPatientRegistrationCreatePenjaminanPage />,
            },

            // patient data
            {
              path: 'patient-data',
              element: <AdmissionPatientDataListPage />,
            },
            {
              path: 'patient-data/create',
              element: <AdmissionPatientDataCreatePage />,
            },
            {
              path: 'patient-data/:id/update',
              element: <AdmissionPatientDataUpdatePage />,
            },

            // visit plan
            {
              path: 'patient-registration/:noSEP/:tglSEP/create-visit-plan',
              element: <AdmissionPatientRegistrationCreateVisitPlanPage />,
            },
            {
              path: 'patient-registration/:noSEP/:tglSEP/update-visit-plan',
              element: <AdmissionPatientRegistrationUpdateVisitPlanPage />,
            },
            {
              path: 'patient-registration/:encounterId/create-visit-plan-non-bpjs',
              element: <AdmissionPatientRegistrationCreateVisitPlanNonBPJSPage />,
            },

            // rujukan
            {
              path: 'patient-registration/:encounterId/create-rujukan',
              element: <AdmissionPatientRegistrationCreateRujukanPage />,
            },
            {
              path: 'patient-registration/:encounterId/update-rujukan',
              element: <AdmissionPatientRegistrationUpdateRujukanPage />,
            },
            // end rujukan
          ],
        },
        // end admission

        // integrations
        {
          path: 'integrations',
          children: [
            {
              element: <Navigate to="/dashboard/integrations/bpjs/queue" replace />,
              index: true,
            },
            {
              path: 'bpjs',
              children: [
                {
                  path: 'queue',
                  element: <IntegrationsBpjsQueue />,
                },
                {
                  path: 'queue/create',
                  element: <IntegrationsBpjsQueueCreate />,
                },
                {
                  path: 'pcare',
                  element: <IntegrationsBpjsPCare />,
                },
                {
                  path: 'pcare/create',
                  element: <IntegrationsBpjsPCareCreate />,
                },
                {
                  path: 'vclaim',
                  element: <IntegrationsBpjsVClaim />,
                },
                {
                  path: 'vclaim/create',
                  element: <IntegrationsBpjsVClaimCreate />,
                },
              ],
            },
          ],
        },
        // end integrations

        // report
        {
          path: 'reports',
          children: [
            {
              element: <Navigate to="/dashboard/reports/material-sale" replace />,
              index: true,
            },
            {
              path: 'material-sale',
              element: <ReportsMaterialSale />,
            },
            {
              path: 'procedure-service',
              element: <ReportsProcedureService />,
            },
          ],
        },
        // end report

        // services
        {
          path: 'services',
          children: [
            // root: path(ROOTS_DASHBOARD, '/services/outpatient'),
            {
              element: <Navigate to="/dashboard/services/outpatient" replace />,
              index: true,
            },
            // outpatient
            {
              path: 'outpatient',
              element: <ServiceOutpatientListPage />,
            },
            {
              path: 'outpatient/:practitionerId/:encounterId/update',
              element: <ServiceOutpatientUpdatePage />,
            },
            {
              path: 'outpatient/:practitionerId/:encounterId/initial-assessment',
              element: <ServiceOutpatientInitAssessmentPage />,
            },
            {
              path: 'outpatient/:practitionerId/:encounterId/icd10',
              element: <ServiceOutpatientIcd10Page />,
            },
            // end outpatient

            // inpatient
            {
              path: 'inpatient',
              element: <ServiceInpatientListPage />,
            },
            // end inpatient
          ],
        },

        // end services

        // END DASHBOARD KEMEDIS
      ],
    },

    // if path is / redirect to /dashboard
    { path: '/', element: <Navigate to="/dashboard" replace /> },

    {
      element: <CompactLayout />,
      children: [
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
